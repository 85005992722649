import { isBrowser } from '@sortlist-frontend/utils';
import Analytics from 'analytics';

import {
  DISABLE_AMPLITUDE_ON_TRACK_EVENTS,
  DISABLED_INTEGRATIONS,
  disabledAmplitudeForSpecificEvent,
  ENABLED_INTEGRATIONS,
  enableDisabledIntegrations,
} from './integrations';
import segmentPlugin from './segmentPlugin';
import { TrackingFunction } from './types';
import { getAbTestData } from './utils';

export type APPS = 'appManager' | 'appAgency' | 'appPublic' | 'appMatch';
const DEFAULT_TRACKER_ID = 'default';
const DEFAULT_PROPS_TRACKER = {
  id: DEFAULT_TRACKER_ID,
  track: () => {},
  trackUntyped: () => {},
  trackWithCallback: () => {},
  page: () => {},
  identify: () => {},
  ready: false,
} as unknown as AnalyticsInstance;
const CALLBACK_TIMEOUT = 2000;

export const analytics = (integrations: Record<string, boolean>, excludedIntegrations?: string[]) => {
  const segmentKey = process.env.NEXT_PUBLIC_SEGMENT_KEY;

  if (!segmentKey) return Analytics({});

  const analytics = Analytics({
    debug: true,
    plugins: [
      segmentPlugin({
        writeKey: segmentKey as string,
        customScriptSrc: process.env.NEXT_PUBLIC_SEGMENT_PROXY as string,
        integrations: {
          ...integrations,
          ...ENABLED_INTEGRATIONS,
          ...enableDisabledIntegrations(DISABLED_INTEGRATIONS),
        },
        excludedIntegrations,
      }) as Record<string, unknown>,
    ],
  });

  if (isBrowser()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore False positive when running type-check
    window.analyticsInstance = analytics;
  }

  return analytics;
};

const execWithTimeout = (callback: (resolve: (value?: unknown) => void, reject: (reason?: string) => void) => void) => {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject('Execution took too much time');
    }, CALLBACK_TIMEOUT);

    callback(
      // Either the tracker passes and it resolves naturally
      (value) => {
        clearTimeout(timer);
        resolve(value);
      },
      // Or the timeout is reached and we force it to stop
      (error) => {
        clearTimeout(timer);
        reject(error);
      },
    );
  });
};

const getLatestAnalyticsInstance = () => {
  return window.analyticsInstance ?? DEFAULT_PROPS_TRACKER;
};

interface AnalyticsInstance {
  ready: boolean;
  track: TrackingFunction;
  identify: (userId: string, trackerProps: Record<string, unknown>, options?: Record<string, unknown>) => void;
  trackWithCallback: (
    trackerName: string,
    trackerProps: Record<string, unknown>,
    callback: () => void,
    options?: Record<string, unknown>,
  ) => Promise<void>;
  page: (props: Record<string, unknown>, options: Record<string, unknown>) => void;
  /**
   * @deprecated Please use `track` instead
   */
  trackUntyped: (trackerName: string, trackerProps?: unknown, options?: Record<string, unknown>) => void;
}

export const getAnalyticsInstance = ({ app, locale }: { app: APPS; locale?: string }): AnalyticsInstance => {
  if (isBrowser()) {
    let analyticsInstance = getLatestAnalyticsInstance();
    const abTests = getAbTestData();

    const track: TrackingFunction = (
      trackerName: string,
      trackerProps: object = {},
      options?: Record<string, unknown>,
      callback?: VoidFunction,
    ) => {
      if (analyticsInstance.id === DEFAULT_TRACKER_ID) analyticsInstance = getLatestAnalyticsInstance();

      analyticsInstance.track(
        trackerName,
        { app, abTests, ...trackerProps },
        {
          ...(locale != null && { context: { locale } }), // if not passed as arg, Segment uses browser local instead of domain or user locale.
          ...options,
          ...disabledAmplitudeForSpecificEvent(trackerName, DISABLE_AMPLITUDE_ON_TRACK_EVENTS),
        },
        callback,
      );
    };

    const trackUntyped = track as AnalyticsInstance['trackUntyped'];

    return {
      ...analyticsInstance,
      ready: !!window?.analyticsInstance,
      track,
      trackUntyped,
      identify: (userId: string, trackerProps: Record<string, unknown>, options?: Record<string, unknown>) => {
        analyticsInstance.identify(userId, { app, ...trackerProps }, options);
      },
      // Some trackers are performed before event that could block them (like 'briefingCompleted' done
      // just before changing the page), so we need to make sure that they were performed before allowing
      // the next actions. Still, we set a timeout to make sure we don't disrupt the service if tracker
      // doesn't pass for whatever reason (Segment down, low internet connection...). Use this one only if
      // necessary, cause it's adding some overhead.
      trackWithCallback: async (
        trackerName: string,
        trackerProps: Record<string, unknown>,
        callback: () => void,
        options?: Record<string, unknown>,
      ) => {
        await execWithTimeout(async (resolve, _reject) => {
          await analyticsInstance.track(trackerName, { app, ...trackerProps }, { ...options, synchronous: true });
          resolve();
        }).finally(() => {
          // No matter what happened before (execution or timeout), we'll perform the callback
          callback();
        });
      },
      page: (props: Record<string, unknown>, options: Record<string, unknown>) => {
        analyticsInstance.page(
          { app, ...props },
          {
            ...options,
            ...disabledAmplitudeForSpecificEvent((props.category as string) || '', DISABLE_AMPLITUDE_ON_TRACK_EVENTS),
          },
        );
      },
    };
  }

  return DEFAULT_PROPS_TRACKER;
};
