import { getCookie } from 'cookies-next';

function abTestCookieToObject(cookie?: string): Record<string, string> | undefined {
  if (cookie == null || cookie === '') return;

  // See libs/feature-flags/src/constants.ts for values
  return cookie.split(',').reduce((memo, exp: string) => {
    const [key, value] = exp.split(':');
    return {
      ...memo,
      [key]: value,
    };
  }, {});
}

export function getAbTestData(): { gbuuid: string; experiments: Record<string, unknown> } | undefined {
  // Also pass GB_UUID and ab test cookie for experiment result tracking
  // See packages/cloudflare-workers/README.md for more info
  const gbuuid = getCookie('gbuuid')?.toString();
  const experiments = abTestCookieToObject(getCookie('ab-tests')?.toString());

  return gbuuid != null && experiments != null ? { gbuuid, experiments } : undefined;
}
